import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import PixelDogImgSrc from "../images/pixel_dog.png"

const PixelDogImg = styled.img`
  width: 50px;
`

const Container = styled.div`
  text-align: center;
  a {
    color: black;
    text-decoration: none;
  }
  a:hover {
    color: green;
  }
`

export default function Header() {
  return (
    <Container>
      <Link to="/">
        <PixelDogImg src={PixelDogImgSrc} alt="Dog by mungang kim from the Noun Project" />
        <h1>dogpark.fyi</h1>
      </Link>
      <p>Some helpful info about San Francisco's dog parks.</p>
    </Container>
  )
}
