import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const FooterContainer = styled.div`
  background: #eee;
  font-size: 0.8em;
  width: 100%;
  padding: 2em;
  box-sizing: border-box;
`
const FooterMain = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

export default function Footer() {
  return (
    <FooterContainer>
      <FooterMain>
        <p>See some missing or incorrect info? <Link to="/something-missing">Get in touch here.</Link></p>
        <p>Dog by mungang kim from the Noun Project</p>
      </FooterMain>
    </FooterContainer>
  )
}
